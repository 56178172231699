import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const TableWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  @media only screen and (max-width: 1000px) {
    font-size: 8px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;
  /* border: 1px solid #ddd; */
`;

const StickyTh = styled.th`
  width: 100%;
  background-color: #7fc8a9;
  color: white;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  @media only screen and (max-width: 1000px) {
    width: 100px;
  }
`;
const Th = styled.th``;

const Thead = styled.thead`
  background-color: #7fc8a9;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

const Tr = styled.tr`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #d5eebb;
  }
`;

const Tbody = styled.tbody`
  height: 100%;
  width: 100%;
`;

const Td = styled.td`
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    width: 100;
  }
`;

const CustomedTableMolecule = (props) => {
  const { columns, rows } = props;

  return (
    <Wrapper>
      <TableWrapper>
        <Table>
          <Thead>
            <Tr>
              {columns.map((col, colIndex) => (
                <StickyTh key={colIndex}> {col.displayName} </StickyTh>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {rows.map((row, rowIndex) => (
              // <tr>
              //   {columns.map((col, index) =>
              //     index === 0 ? (
              //       <StickyTd>{row[col.name]}</StickyTd>
              //     ) : (
              //       <Td>{row[col.name]}</Td>
              //     )
              //   )}
              // </tr>
              <Tr key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <Td key={colIndex}>{row[col.name]}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableWrapper>
    </Wrapper>
  );
};

export default CustomedTableMolecule;
