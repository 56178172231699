import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectGraphBar from './SelectGraphBar';
import RenderGraph from './RenderGraph';
import EstimationList from './EstimationList.js';
import { convertDictIntoCsv, getDailyOnionFromHourlyData } from '../../../utils/DataUtils.js';
import {
  getHourlyPhotosynthesisFromData,
  getPhenologyRadishFromData,
  getPhotosynthesisAPI, getUserPhotosynthesisAPI
} from '../../../utils/EpinetAPIUtils.js';
import axios from 'axios';

const GraphWrapper = styled.div`
    
`;

const ListWrapper = styled.div`
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
`;


const EstimationGraph = ({ lat, lng, user, plant, region}) => {
  const [beginDate, setBeginDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [factor, setFactor] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [phenologyData, setPhenologyData] = useState(null);
  const [columns, setColumns] = useState(null);
  const [rows, setRows] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const mobile = localStorage.getItem('user');

  const searchPhenologyData = () => {
    let farmWeatherForecastAPI = getPhotosynthesisAPI(startDate, endDate, region, lat, lng, plant);
    updatePhenologyData(startDate,
      endDate,
      region,
      lat,
      lng,
      plant,
      farmWeatherForecastAPI
    );
  }


// 생육모델구동결과 반환받기
  const updatePhenologyData = async (startDate, endDate, region, lat, lng, plant, farmWeatherForecastAPI) => {
    if(!startDate || !endDate || !region || !lat || !lng || !plant) return;
    console.log('조회함');
    setPhenologyData(null);
    setIsSearch(false);
    setLoading(true);
    setFactor('');

    if(plant === 'onion') {
      let tempDate = endDate;
      tempDate.setDate(tempDate.getDate() + 1);
      setEndDate(tempDate);
    }

    // let farmWeatherForecastAPI = getUserPhotosynthesisAPI(startDate, endDate, region, lat, lng, plant, mobile);
    try {
      let response = await axios.get(farmWeatherForecastAPI);
      if (response.status !== 200) {
        alert('해당 기간의 생육정보를 불러오는데 실패했습니다');
        setLoading(false);
        return;
      }
      // setShowGraph(true);
      const data = response.data;
      // console.log(response.status);
      if (plant === 'radish') {
        const refinedData = getPhenologyRadishFromData(data.phenology);
        setPhenologyData(refinedData);
        setEndDate(new Date(refinedData.rows[refinedData.rows.length - 1].datetime));
      } else if (plant === 'onion') {
        const processedData = getHourlyPhotosynthesisFromData(data.phenology);
        const refinedData = getDailyOnionFromHourlyData(processedData);
        setPhenologyData(refinedData);
        setEndDate(new Date(refinedData.rows[refinedData.rows.length - 1].datetime));
      }
    } catch (e) {
      alert('해당 기간의 생육정보를 불러오는데 실패했습니다');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(!user) return;
    let formattedStartDate = new Date(user.begindate);
    setBeginDate(formattedStartDate);
    setStartDate(formattedStartDate);
    setIsSearch(true);
  }, [user]);

  useEffect(() => {
    if(!isSearch) return;
    let farmWeatherForecastAPI = getUserPhotosynthesisAPI(startDate, endDate, region, lat, lng, plant, mobile)
    updatePhenologyData(startDate,
      endDate,
      region,
      lat,
      lng,
      plant,
      farmWeatherForecastAPI
    );
  }, [isSearch]);

  useEffect(() => {
    if (!phenologyData || !plant ) return;
    setColumns(phenologyData.columns);
    setRows(getRowsFromPhenologyData(phenologyData));
  }, [phenologyData]);

  useEffect(() => {
    if (!columns || !rows) return;
    // setPropsColumns(columns);
    const contents = convertDictIntoCsv(columns, rows);
    const blob = new Blob(['\ufeff' + contents], {
      type: 'text/csv;charset=utf-8'
    });
    const url = URL.createObjectURL(blob);
    setDownloadUrl(url);
  }, [columns, rows]);

  const getRowsFromPhenologyData = phenologyData => {
    return phenologyData.rows;
  };

  return (
    <GraphWrapper>
      <SelectGraphBar
        beginDate={beginDate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setIsSearch={setIsSearch}
        searchPhenologyData={searchPhenologyData}
      />
      <ListWrapper>
      <EstimationList
        style={{ display: (phenologyData) ? 'block' : 'none' }}
        loading={loading}
        phenologyData={phenologyData}
      />
      </ListWrapper>
      <RenderGraph
        startDate={startDate}
        endDate={endDate}
        user={user}
        isSearch={isSearch}
        factor={factor}
        phenologyData={phenologyData}
        rows={rows}
        downloadUrl={downloadUrl}
        list={columns}
        setFactor={setFactor}
        loading={loading}
      />
    </GraphWrapper>
  );
};

export default EstimationGraph;