import React, { useEffect, useState } from 'react';
import ListCard from './ListCard';
import axios from 'axios';
import { getFarmWeatherForecastAPI } from '../../../utils/EpinetAPIUtils.js';
import Areas from '../../../data/serviceAreas.json';


export const getRefinedData = data => {
  return data.map(d => {
    return Math.round(d * 100) / 100.0;
  })
}

const WeatherList = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [latlng, setLatlng] = useState(props.latlng);
  const [user, setUser] = useState(props.user);
  const [date, setDate] = useState(props.date);
  const list = ['최저기온(℃)', '최고기온(℃)', '습도(%)', '강우량(mm)', '일사량(MJ/㎡)', '일조시간(h)'];
  const [weatherInfo, setWeatherInfo] = useState(null);

  useEffect(() => {
    setLatlng(props.latlng);
  }, [props.latlng]);

  useEffect(() => {
    setDate(props.date);
  }, [props.date]);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  useEffect(() => {
    const getFarmWeatherInfo = async (lat, lng, city, date) => {
      const farmWeatherDataAPI = getFarmWeatherForecastAPI(lat, lng, city, date);

      try {
        setLoading(true);
        setError(null);
        await axios.get(farmWeatherDataAPI).then((response) => {
          const data = response.data;
          let refinedData = getRefinedData(data.values[2]);
          setWeatherInfo(refinedData);
        });
        // }
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };
    if (latlng && user) {
      getFarmWeatherInfo(latlng.lat, latlng.lng, user.region, date);
    }
  }, [latlng, date]);


 const RenderWeatherInfo = ({data, list}) => {
    if(!data || !list) {
      return <div>
        데이터를 생성중입니다...
      </div>;
    }

   const renderedListCards = list.map((item, index) => (
     <ListCard key={index} name={item} value={data[index]} />
   ));

   return (
     <>
       {renderedListCards}
     </>
   );
}

  return (
    <>
      <RenderWeatherInfo data={weatherInfo} list={list}></RenderWeatherInfo>
    </>
  );
};

export default WeatherList;
