import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  color: #444941;
  box-sizing: border-box;
  padding-bottom: 0.5rem;
`;

const SectionTitleComponent = (props) => {
  return <Wrapper>{props.children}</Wrapper>;
};

export default SectionTitleComponent;
