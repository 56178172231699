import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
    width: 30%;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #D5EEBB;
    padding: 1%;
    
    &:nth-child(3n + 1) {
        margin-right: 2%;
    }
    
    &:nth-child(3n + 2) {
        margin-right: 2%;
    }
`;

const CardName = styled.div`
    padding-bottom: 5px;
    text-align: center;
    color: #436B14;
    font-size: 1.2em;
`;

const CardContent = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    font-size: 1.2em;
    font-weight: 500;
`;

const ListCard = (props) => {
  return (
    <Card>
      <CardName>{props.name}</CardName>
      <CardContent>{props.value}</CardContent>
    </Card>
  );
};

export default ListCard;