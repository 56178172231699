import React, { useEffect, useState } from 'react';
import ReactHighcharts from 'react-highcharts';
import LoadingImage from '../LoadingImage.js';

const PhenologyChart = (props) => {
  const [dateArray, setDateArray] = useState([]);

  useEffect(() => {
    if(!props.rows) return;
    generateDateArray(props.rows.length);
  }, [props.rows]);

  const generateDateArray = (length) => {
    setDateArray(new Array(length).fill(null));

    const startDateObj = new Date(props.rows[0].datetime);
    const endDateObj = new Date(props.rows[props.rows.length - 1].datetime);

    if (!isNaN(startDateObj.getTime()) && !isNaN(endDateObj.getTime())) {
      const dates = [];
      let currentDate = startDateObj;

      while (currentDate <= endDateObj) {
        let tempMonth = currentDate.getMonth() + 1;
        let tempDate = currentDate.getDate();
        dates.push(tempMonth + '-' + tempDate);
        currentDate.setDate(currentDate.getDate() + 1);
      }
      setDateArray(dates);
    } else {
      // Handle invalid dates
      console.error('Invalid dates');
    }
  };


  const getSeries = () => {
    if(!props.user || !props.rows) return;
    let plant = props.user.plant;

    let onion = [
      {
        name: '광합성량',
        engName: 'photosynthesis',
        data: getSeriesData('photosynthesis')
      },
      {
        name: '누적 dvs',
        engName: 'totaldvs',
        data: getSeriesData('totaldvs')
      },
      {
        name: '엽수',
        engName: 'leafNumber',
        data: getSeriesData('leafNumber')
      },
      {
        name: '엽면적',
        engName: 'leafArea',
        data: getSeriesData('leafArea')
      },
      {
        name: 'LAI',
        engName: 'lai',
        data: getSeriesData('lai')
      }
    ];

    let radish = [
      {
        name: '엽수',
        engName: 'NL',
        data: getSeriesData('NL')
      },
      {
        name: '엽면적지수',
        engName: 'lai',
        data: getSeriesData('lai')
      },
      {
        name: '광합성량',
        engName: 'netTmm',
        data: getSeriesData('netTmm')
      },
      {
        name: '지상부 건물중량(g)',
        engName: 'pltleafDW',
        data: getSeriesData('pltleafDW')
      },
      {
        name: '지상부 생체중량(g)',
        engName: 'pltleafFW',
        data: getSeriesData('pltleafFW')
      },
      {
        name: '지하부 건물중량(g)',
        engName: 'pltrootDW',
        data: getSeriesData('pltrootDW')
      },
      {
        name: '지하부 생체중량(g)',
        engName: 'pltrootFW',
        data: getSeriesData('pltrootFW')
      },
      {
        name: '장해피해율',
        engName: 'lossPer',
        data: getSeriesData('lossPer')
      },
      {
        name: '추대여부',
        engName: 'bolting',
        data: getSeriesData('bolting')
      }
    ]
    let target = plant === 'onion' ? onion : radish;
    if(!props.factor) {
      return target
    } else {
      console.log(props.factor);
      return target.filter(item => item.engName === props.factor);
    }
  }

  const getSeriesData = (title) => {
    if(!props.rows) return;
    let rowArray = [];
    props.rows.forEach(item => {
        rowArray.push(parseFloat(item[title]));
    });
    return rowArray;
  }

  // 그래프 옵션 설정
  const config = {
      title: {
        text: null
      },
      xAxis: {
        categories: dateArray
      },
      yAxis: {
        title: {
          text: props.factor
        }
      },
      series: getSeries()
  }

  return (
    <div>
      <ReactHighcharts
        config={config} />
    </div>
  );
}

export default PhenologyChart;