import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import brandSymbol from '../../images/brand-symbol.png';
import region from '../../data/serviceAreas.json';
import rightArrow from '../../images/rightArrow.png';
import closeImage from '../../images/closeBtn.png';
import loginByMobile from './LoginByMobile.js';

const RegisterTitle = styled.div`
    padding: 20px 30px;
    border-bottom: 1px solid #d6d6d6;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Img = styled.img`
    height: 1.4em;
    box-sizing: border-box;
`;

const MainTitle = styled.div`
    color: #333333;
    font-size: 1.1em;
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-weight: 700;
`;

const SubTitle = styled.div`
    color: #666666;
    font-size: 1.1em;
    box-sizing: border-box;
    font-weight: 550;
`;

const WriteDetailFarmInfo = styled.div`
    padding: 20px 30px;
`;
const InputBox = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;

    div {
        width: 100px;
        font-size: 0.9em;
        font-weight: 50;
        
        white-space: nowrap;
    }

    input {
        outline: none;
        border: 1px solid rgba(183, 183, 183, 0.72);
        border-radius: 2px;
        padding: 5px;
        width: 220px;
        
        @media only screen and (max-width: 600px) {
            width: 75%;
        }
    }

    select {
        outline: none;
        border: 1px solid rgba(183, 183, 183, 0.72);
        border-radius: 2px;
        padding: 5px;
        width: 232px;

        @media only screen and (max-width: 600px) {
            width: 80%;
        }
    }
`;

const WriteDetailInfoTotle = styled.div`
    font-size: 1.1em;
    font-weight: 700;
    margin-bottom: 20px;
`;

const RegisterDoneBtn = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        width: 60%;
        padding: 5px;
        margin: 20px auto 30px;
        font-weight: 700;
        background-color: #00733f;
        border: 1px solid #00733f;
        border-radius: 2px;
        font-size: 1em;
        color: white;

        &:hover {
            cursor: pointer;
        }
    }
`;

const GoBack = styled.div`
    font-size: 0.8em;
    color: #0b60d2;
    text-decoration: underline;
    display: flex;
    align-items: center;
    margin: 10px;

    &:hover {
        cursor: pointer;
    }
`;

const MoveToMain = styled.img`
    width: 15px;
    height: 15px;
    margin-top: 2px;
    transform: rotate(180deg);
`;

const ModelCloseBtn = styled.button`
    padding: 0;
    border: none;
    background-color: white;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
        cursor: pointer;
    }
`;

const CloseBtn = styled.img`
    width: 100%;
    height: 100%;
`;

//주소로 좌표 얻어오기
const getLonLatFromVworld = async (inputAddress) => {
  const { kakao } = window;
  const geocoder = new kakao.maps.services.Geocoder();

  if (inputAddress.trim === '') return;

  const result = await new Promise((resolve, reject) => {
    geocoder.addressSearch(inputAddress, function(result, status) {
      if (status === kakao.maps.services.Status.OK) {
        resolve(result);
      }
    });
  });

  if (result) {
    const address = result[0].address.address_name;
    const lon = parseFloat(result[0].x);
    const lat = parseFloat(result[0].y);

    return { address, lon, lat };
  } else {
    alert('입력하신 주소 검색에 실패하였습니다<br>정환한 주소를 입력해주세요');
  }

};

const RegisterFarm = props => {
  const [inputValues, setInputValues] = useState(['', '', '무', '고창', '', '', '']);
  const [validations, setValidations] = useState([false, false, true, true, false, false, false]);

  useEffect(() => {
    for (let i = 0; i < inputValues.length; i++) {
      checkIfNull(i);
    }
  }, [inputValues]);

  const checkIfNull = index => {
    validations[index] = inputValues[index].trim() !== '';
  };

  const inputDetailInfo = (title, type, index) => {

    const changeInputValue = e => {
      e.persist();
      setInputValues(originValues => {
        let newValues = [...originValues];
        newValues[index] = e.target.value;
        return newValues;
      });
    };

    return (
      <InputBox>
        <div>{title}</div>
        <input onChange={changeInputValue} type={type} placeholder={title + ' 입력'} />
      </InputBox>
    );
  };

  const selectList = {
    '작목': ['무', '양파'],
    '주산지': region.map(r => r.areaName_kor)
  };

  //셀렉트 박스를 그릴 대상이 되는 리스트 뽑기
  const getSelectKey = listTitle => {
    let selectKeys = Object.keys(selectList);
    let key = null;
    selectKeys.forEach(k => {
      if (k.trim() === listTitle) {
        key = k;
      }
    });
    return key;
  };

  // 옵션리스트 렌더링
  const getRenderedOptions = key => {
    let list = selectList[key];
    return list.map(l => <option key={l}>{l}</option>);
  };

  const selectDetailInfo = (listName, index) => {
    let selectKey = getSelectKey(listName);

    const changeInputValue = e => {
      e.persist();
      setInputValues(originValues => {
        let newValues = [...originValues];
        newValues[index] = e.target.value;
        return newValues;
      });
    };

    return (
      <InputBox>
        <div>{listName}</div>
        <select onChange={changeInputValue}>
          {getRenderedOptions(selectKey)}
        </select>
      </InputBox>
    );
  };

  const checkValidate = async e => {
    for (const v of validations) {
      if (v === false) {
        alert('입력칸을 모두 채워주세요');
        return;
      }
    }

    if (!inputValues[4].includes(inputValues[3])) {
      console.log(inputValues[4]);
      console.log(inputValues[3]);
      alert('입력한 지역과 주소가 일치하지 않습니다');
      return;
    }

    // 입력한 주소로 카카오api에 검색하여 좌표 반환
    const { address, lon, lat } = await getLonLatFromVworld(inputValues[4]);
    alert(`입력하신 주소는 [${address}]입니다`);

    const newMember = {
      'owner': inputValues[0],
      'phonemobile': inputValues[1],
      'plant': getPlantEngName(inputValues[2]),
      'region': getRegionEngName(inputValues[3]),
      'address': address,
      'lon': lon,
      'lat': lat,
      'begindate': inputValues[5],
      'density': inputValues[6]
    };
    console.log(newMember);

    const requestInfo = {
      headers: {
        'content-type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(newMember)
    };

    let result = await fetch('https://wds.agdcm.kr/regist/owner_regist', requestInfo).then(res => res.json()); //서버에 가입정보 보내기

    if (localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
    localStorage.setItem('user', inputValues[1]);
    window.location.href = '/farmInfo';
  };

  const getRegionEngName = (korName) => {
    return region.filter(r =>
      r.areaName_kor === korName
    )[0].areaName_eng;
  };

  const getPlantEngName = (korName) => {
    return korName === '무' ? 'radish' : 'onion';
  };

  const goBackToPreviousModal = () => {
    props.goToPreviousModal();
  };

  const closeAllModal = () => {
    props.closeRegisterModal();
  };

  return (
    <>
      <ModelCloseBtn onClick={closeAllModal}><CloseBtn src={closeImage} alt={'close'} /></ModelCloseBtn>
      <RegisterTitle>
        <Img src={brandSymbol} alt='채소 주산지 생육정보 시스템' />
        <MainTitle>채소 주산지</MainTitle>
        <SubTitle>생육정보 시스템</SubTitle>
      </RegisterTitle>
      <WriteDetailFarmInfo>
        <WriteDetailInfoTotle>농장 등록하기</WriteDetailInfoTotle>
        {inputDetailInfo('농장주 이름', 'text', 0)}
        {inputDetailInfo('휴대전화번호', 'text', 1)}
        {selectDetailInfo('작목', 2)}
        {selectDetailInfo('주산지', 3)}
        {inputDetailInfo('농장주소', 'text', 4)}
        {inputDetailInfo('정식일', 'date', 5)}
        {inputDetailInfo('재식밀도', 'text', 6)}
      </WriteDetailFarmInfo>
      <RegisterDoneBtn>
        <button onClick={checkValidate}>등록하기</button>
      </RegisterDoneBtn>
      <GoBack onClick={goBackToPreviousModal}><MoveToMain src={rightArrow} alt='등록하기' />이전으로</GoBack>
    </>
  );

};

export default RegisterFarm;