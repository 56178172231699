import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import CustomedTableMolecule from '../../components/UI/molecules/CustomedTableMolecule';
import LoadingImage from './LoadingImage';
import { convertDictIntoCsv, getDailyOnionFromHourlyData } from '../../utils/DataUtils';
import { getHourlyPhotosynthesisFromData, getPhenologyRadishFromData } from '../../utils/EpinetAPIUtils.js';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 1rem;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const Button = styled.a`
  display: block;
  box-sizing: border-box;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  margin: 0.5rem;
  background-color: #426cdd;
  cursor: pointer;
  font-size: 1rem;
  border-style: none;
  border-radius: 0.5rem;
  transition: all 100ms ease-in 0.05s;
  text-decoration: none;
  color: white;
  line-height: 1.5rem;
  text-align: center;
  &:hover {
    background-color: #90ba27;
    color: white;
    font-weight: bold;
  }
`;

const IconImage = styled.img`
  height: 1rem;
  padding-right: 5px;
  box-sizing: border-box;
`;

const PhenologyEstimationContents = (props) => {
  const {
    cropType,
    selectedArea,
    startDate,
    endDate,
    selectedMarker,
    selectedFarmAddress,
    getPhenologyEstimaionAPI,
    getRefinedPheonologyEstimationData,
    refd,
  } = props;
  const [phenologyEstimation, setPhenologyEstimation] = useState();
  const [downloadUrl, setDownloadUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const idxDate = startDate ? new Date(startDate) : new Date();
  idxDate.setDate(idxDate.getDate() - 1);

  const isValidDuration = (startDate, endDate) => {
    if (startDate <= endDate) {
      return true;
    } else {
      return false;
    }
  };

  const updatePhenologyEstimation = async (
    startDate,
    endDate,
    cityName,
    lat,
    lng,
    crop_name
  ) => {
    const farmWeatherForecastAPI = getPhenologyEstimaionAPI(
      startDate,
      endDate,
      cityName,
      lat,
      lng,
      crop_name
    );
    try {
      // if (startDate && endDate && lat && lng && cityName) {
      props.setDoSearch(false);
      setLoading(true);
      setError(null);
      setPhenologyEstimation(null);
      setDownloadUrl(null);
      await axios.get(farmWeatherForecastAPI).then((response) => {
        const data = response.data;
        if (crop_name === 'radish') {
          const refinedData = getPhenologyRadishFromData(data.phenology);
          setPhenologyEstimation(refinedData);
          console.log(refinedData);
        } else if (crop_name === 'onion') {
          const processedData = getHourlyPhotosynthesisFromData(data.phenology);
          const refinedData = getDailyOnionFromHourlyData(processedData);
          setPhenologyEstimation(refinedData);
          console.log(refinedData);
        }
        const contents = convertDictIntoCsv(
          phenologyEstimation.columns,
          phenologyEstimation.rows
        );
        const blob = new Blob(['\ufeff' + contents], {
          type: 'text/csv;charset=utf-8',
        });
        const url = URL.createObjectURL(blob);
        setDownloadUrl(url);
      });
      // }
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if(!props.doSearch) return;
    updatePhenologyEstimation(
      startDate,
      endDate,
      selectedArea.areaName_eng,
      selectedMarker.lat,
      selectedMarker.lng,
      cropType.title_eng
    );
  }, [refd], props.doSearch); // cropType, selectedMarker, selectedArea, startDate, endDate]);

  return (
    <Wrapper>
      <ContentsWrapper>
        {phenologyEstimation != null ? (
          <>
            <Button
              href={downloadUrl}
              download={`${cropType.title_ko}_생육모형_${selectedFarmAddress}.csv`}
            >
              <span role="img" aria-label={cropType.title_eng}>
                <IconImage src={cropType.iconImage} />
              </span>
              {`${cropType.title_ko} 생육모형 구동결과 다운로드`}
            </Button>
            <CustomedTableMolecule
              columns={phenologyEstimation.columns}
              rows={phenologyEstimation.rows}
            />
          </>
        ) : (
          <LoadingImage />
        )}
      </ContentsWrapper>
    </Wrapper>
  );
};

export default PhenologyEstimationContents;
