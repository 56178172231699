import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import LoadingImage from './LoadingImage';
import CustomedTableMolecule from '../../components/UI/molecules/CustomedTableMolecule';
import { convertDictIntoCsv } from '../../utils/DataUtils';
import { getDailyFarmWeatherEstimationFromData } from '../../utils/EpinetAPIUtils';
import { getDailyAndHourlyFarmWeatherForecastDuringTheGivenPeriod } from '../../utils/EpinetAPIUtils';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 1rem;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const Button = styled.a`
  display: block;
  box-sizing: border-box;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  margin: 0.5rem;
  background-color: #426cdd;
  cursor: pointer;
  font-size: 1rem;
  border-style: none;
  border-radius: 0.5rem;
  transition: all 100ms ease-in 0.05s;
  text-decoration: none;
  color: white;
  line-height: 1.5rem;
  text-align: center;
  &:hover {
    background-color: #90ba27;
    color: white;
    font-weight: bold;
  }
`;

const FarmWeatherForecastDuringTheGivenPeriodContents = (props) => {
  const { selectedArea, startDate, endDate, selectedMarker,refd} = props;

  const [farmWeatherForecastData, setFarmWeatherForecastData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState('');
  const idxDate = startDate ? new Date(startDate) : new Date();
  idxDate.setDate(idxDate.getDate() - 1);

  const isValidDuration = (startDate, endDate) => {
    if (startDate <= endDate) {
      return true;
    } else {
      return false;
    }
  };

  const updateFarmWeatherForecastData = async (
    startDate,
    endDate,
    cityName,
    lat,
    lng
  ) => {
    const farmWeatherForecastAPI =
      getDailyAndHourlyFarmWeatherForecastDuringTheGivenPeriod(
        startDate,
        endDate,
        cityName,
        lat,
        lng
      );
    try {
      // if (startDate && endDate && lat && lng && cityName) {
      setError(null);
      setLoading(true);
      setFarmWeatherForecastData(null);
      setDownloadUrl(null);

      await axios.get(farmWeatherForecastAPI).then((response) => {
        const data = response.data;
        const refinedDailyData = getDailyFarmWeatherEstimationFromData(
          data.daily
        );
        setFarmWeatherForecastData(refinedDailyData);
        const contents = convertDictIntoCsv(
          refinedDailyData.columns,
          refinedDailyData.rows
        );
        const blob = new Blob(['\ufeff' + contents], {
          type: 'text/csv;charset=utf-8',
        });
        const url = URL.createObjectURL(blob);
        setDownloadUrl(url);
      });
      // }
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    updateFarmWeatherForecastData(
      startDate,
      endDate,
      selectedArea.location.cityName_eng,
      selectedMarker.lat,
      selectedMarker.lng
    );
  }, [refd]); // selectedMarker, selectedArea, startDate, endDate]);

  return (
    <Wrapper>
      <ContentsWrapper>
        {farmWeatherForecastData !== null ? (
          <>
            <Button
              href={downloadUrl}
              // download={`농장예보_${selectedFarmAddress}.csv`}
              download={`농장예보.csv`}
            >
              농장예보 다운로드
            </Button>
            <CustomedTableMolecule
              columns={farmWeatherForecastData.columns}
              rows={farmWeatherForecastData.rows}
            />
          </>
        ) : (
          <LoadingImage />
        )}
      </ContentsWrapper>
    </Wrapper>
  );
};

export default FarmWeatherForecastDuringTheGivenPeriodContents;
