import React from 'react';
import styled from 'styled-components';
import serviceAreas from '../../data/serviceAreas.json';
import { logDOM } from '@testing-library/react';

const Select = styled.select`
  color: #555;
  vertical-align: center;
  border: 0.1rem solid #ccc;
  border-radius: 0.3rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 0.2rem;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background-color: #90ba27;
    color: white;
    font-weight: bold;
  }
`;

const Option = styled.option`
  vertical-align: center;
  background-color: white;
  text-align: center;
  padding: 0.2rem;
  color: #555;
  &:hover {
    background-color: #90ba27;
    color: white;
    font-weight: bold;
  }
`;

const SelectArea = (props) => {
  const { selectedArea, setSelectedArea } = props;

  const onChange = (e) => {
    const areaId = e.target.value;
    const targetAreaItem = serviceAreas.filter(
      (item) => item.id === parseInt(areaId)
    )[0];
    if (targetAreaItem) {
      setSelectedArea(targetAreaItem);
    }
  };

  return (
    <Select value={selectedArea.id} onChange={onChange}>
      {serviceAreas.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.areaName_kor}
        </Option>
      ))}
    </Select>
  );
};

export default SelectArea;
