import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import 'react-datepicker/dist/react-datepicker.css';
import './SelectDate.css';

registerLocale('ko', ko);

function SelectDate(props) {
  const { onChangeDate, selectedDate } = props;
  const [date, setDate] = useState(new Date());

  return (
    <DatePicker
      locale="ko"
      dateFormat="yyyy-MM-dd"
      selected={selectedDate ? selectedDate : date}
      onChange={(e) => {
        if (onChangeDate(e)) {
          setDate(e);
        }
      }}
      popperModifiers={{ preventOverflow: { enabled: true } }}
      popperPlacement="auto"
    />
  );
}

export default SelectDate;
