import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import * as EpinetApiUtils from '../../utils/EpinetAPIUtils';
import { getRoundOffFloor } from '../../utils/NumberUtil';

const ColorLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 7rem;
  padding: 0;
  margin-right: 1rem;
  margin-bottom: 2rem;
  opacity: 0.95;
  line-height: 1rem;
  font-size: 1rem;
  background-color: #f2f2f2;
  border-radius: 0.3rem;
  @media only screen and (max-width: 1000px) {
    width: 4rem;
    font-size: 10px;
  }
`;

const ColorLengedTitle = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0.2rem;
  background: #7fc8a9;
  background: gray;
  color: white;
  /* background: #b2b5b1;
  background: #444941; */
  text-align: center;
  /* border-radius: 0.1rem;
  border: 0.1rem solid #d6dcd9; */
`;

const ColorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 7rem;
  padding: 0.5rem;
  /* background: #d6dcd9;
  background: #5f7a61;
  /* border-radius: 0.1rem;
  border: 0.1rem solid #d6dcd9; */
  @media only screen and (max-width: 1000px) {
    width: 4rem;
    font-size: 8px;
  }
`;

const ColorRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 7rem;
  height: 1.5rem;
  padding: 0;
  margin: 0;
  @media only screen and (max-width: 1000px) {
    width: 4rem;
    font-size: 8px;
  }
`;

const Color = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 1.5rem;
  box-sizing: border-box;
  padding-right: 0;
  margin: 0;
  background: ${(props) => (!props.color ? 'white' : props.color)};
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: gray;
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    width: 2rem;
    font-size: 8px;
  }
`;

const ColorIndex = styled.div`
  align-self: flex-end;
  box-sizing: border-box;
  width: 1rem;
  margin: 0;
  padding-right: 0.2rem;
  font-size: 0.6rem;
  text-align: right;
`;

const WeatherValue = styled.div`
  background: white;
  opacity: 0.5;
  color: black;
  box-sizing: border-box;
  border-radius: 0.1rem;
  padding: 0.03rem;
  margin: 0;
  line-height: 0.9rem;
  font-size: 0.9rem;
  text-align: center;
`;

function WeatherColorChartWrapper(props) {
  const {
    weatherColorChart,
    minMaxValue,
    selectedDate,
    selectedArea,
    selectedMarker,
    selectedWeather,
  } = props;
  const [markerWeatherValue, setMarkerWeatherValue] = useState(null);

  const getColors = (weatherColorChart, minMaxValue) => {
    let filteredColors = [];
    if (weatherColorChart !== null && minMaxValue !== null) {
      weatherColorChart.forEach((color, idx) => {
        if (
          color.nextValue >= minMaxValue.min &&
          color.value <= minMaxValue.max
        ) {
          filteredColors.push(color);
        }
      });
      filteredColors.reverse();
    }
    return filteredColors;
  };

  const updateMarkerWeatherValue = async () => {
    const date = selectedDate;
    const cityName = selectedArea.areaName_eng;
    const { lat, lng } = selectedMarker;
    const weatherOptionTitle = selectedWeather.title;
    const markerWeatherValueAPI = EpinetApiUtils.getWeatherValueOfMarkerAPI(
      date,
      cityName,
      lat,
      lng,
      weatherOptionTitle
    );
    await axios.get(markerWeatherValueAPI).then((response) => {
      const data = response.data;
      const markerWeatherValue = EpinetApiUtils.getWeatherValueFromData(
        data,
        date,
        lat,
        lng,
        weatherOptionTitle
      );
      setMarkerWeatherValue(markerWeatherValue);
    });
  };

  useEffect(() => {
    updateMarkerWeatherValue();
  }, [selectedMarker]);

  const colors = useMemo(
    () => getColors(weatherColorChart, minMaxValue),
    [weatherColorChart, minMaxValue]
  );

  return (
    <>
      <ColorLegendWrapper>
        <ColorLengedTitle>
          {selectedWeather.title_kor}({selectedWeather.measurementUnit})
        </ColorLengedTitle>
        <div>
          <ColorsWrapper>
            {colors.map((item, idx) => (
              <ColorRowWrapper key={`colorWrapper-${item.id}`}>
                <ColorIndex>{item.value}</ColorIndex>
                <Color color={item.color}>
                  {((!markerWeatherValue && markerWeatherValue === 0) ||
                    markerWeatherValue) &&
                  markerWeatherValue >= item.value &&
                  markerWeatherValue < item.nextValue ? (
                    <WeatherValue>
                      {getRoundOffFloor(markerWeatherValue, 2)}
                    </WeatherValue>
                  ) : null}
                </Color>
              </ColorRowWrapper>
            ))}
          </ColorsWrapper>
        </div>
      </ColorLegendWrapper>
    </>
  );
}

export default WeatherColorChartWrapper;
