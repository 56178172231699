import React from 'react';
import styled from 'styled-components';

const Select = styled.select`
    color: #555;
    vertical-align: center;
    border: 0.1rem solid #ccc;
    border-radius: 0.3rem;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    padding: 0.4rem;
    font-size: 15px;
    cursor: pointer;

    &:hover {
        background-color: #90ba27;
        color: white;
        font-weight: bold;
    }
`;

const Option = styled.option`
    vertical-align: center;
    background-color: white;
    text-align: center;
    padding: 0.2rem;
    color: #555;

    &:hover {
        background-color: #90ba27;
        color: white;
        font-weight: bold;
    }
`;


const SelectFarm = (props) => {

  return (
    <Select name='' id=''>
      <Option value=''>{props.address}</Option>
    </Select>
  );
};

export default SelectFarm;