import React from 'react';
import styled from 'styled-components';
import SelectWeather from '../UI/SelectWeather';
import SelectDate from '../UI/SelectDate';
import SelectArea from '../UI/SelectArea';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  font-size: 15px;
  width: 100%;
`;

const P = styled.div`
  padding-right: 5px;
`;

const TodayPlaceWeatherMolecule = (props) => {
  const {
    selectedWeather,
    setSelectedWeather,
    selectedDate,
    onChangeDate,
    selectedArea,
    setSelectedArea,
  } = props;

  return (
    <Wrapper>
      <SelectDate selectedDate={selectedDate} onChangeDate={onChangeDate} />
      <P>의</P>
      <SelectArea
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
      />
      <P />
      <SelectWeather
        selectedWeather={selectedWeather}
        setSelectedWeather={setSelectedWeather}
      />
    </Wrapper>
  );
};

export default TodayPlaceWeatherMolecule;
