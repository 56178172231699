import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    /* color: #555; */
    font-size: 1.1rem;
    font-weight: 600;
    box-sizing: border-box;
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //flex-wrap: wrap;

    input {
        outline: none;
        border: 1px solid rgba(183, 183, 183, 0.72);
        border-radius: 2px;
        padding: 5px;
        width: 220px;
        margin-right: 5px;
    }

    button {
        background-color: #416bdb;
        color: white;
        border-radius: 5px;
        border: 1px solid #416bdb;
        padding: 3px;
        
        &:hover {
            cursor: pointer;
        }
    }
`;

const MarkerImage = styled.img`
  height: 1.5rem;
  box-sizing: border-box;
  padding-right: 5px;
`;

const DisplayMapMarkerAddress = (props) => {

  const [inputValue, setInputValue] = useState(props.address || '');

  useEffect(() => {
    // props.address가 변경될 때마다 inputValue 업데이트
    setInputValue(props.address);
  }, [props.address]);

  const getLonLat = e => {
    e.preventDefault();
    props.getLonLatFromVworld(e.target.previousSibling.value);
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <Wrapper>
      <MarkerImage src="https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png" />
      {/*{address !== null ? <>{address}</> : <LoadingImage />}*/}
      <input value={inputValue} readOnly={false} onChange={handleInputChange} type='text' placeholder='지번주소를 입력해주세요' />
      <button onClick={getLonLat}>이동하기</button>
    </Wrapper>
  );
};

export default DisplayMapMarkerAddress;
