import React from 'react';
import styled from 'styled-components';
import SelectDate from '../atoms/SelectDate';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
`;
const Label = styled.div`
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding-bottom: 2px;
`;

function DateAndLabel(props) {
  const { label, selectedDate, onChangeDate } = props;
  return (
    <Wrapper>
      <Label>{label}</Label>
      <SelectDate selectedDate={selectedDate} onChangeDate={onChangeDate} />
    </Wrapper>
  );
}

export default DateAndLabel;
