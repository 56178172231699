import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import SelectFarm from './SelectFarm';

const Div = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    // 줄바꿈 안함
    white-space: nowrap;
    margin: 5px;
`;

const P = styled.p`
    font-weight: bold;
    margin: 0 10px 0 0;
`;

const SelectBar = (props) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    props.setDate(date);
  }, [date]);

  return (
    <Div>
      <Label>
        <P>
          날짜선택
        </P>
        <DatePicker
          inputmode="none"
          locale="ko"
          dateFormat="yyyy-MM-dd"
          selected={props.date}
          onChange={(e) => {
            setDate(e);
          }}
        />
      </Label>
      <Label>
        <P>
          농지선택
        </P>
        <SelectFarm address={props.address} />
      </Label>
    </Div>
  );
};

export default SelectBar;
