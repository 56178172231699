import * as DateUtils from './DateUtils';

export function getEpinetMapData(cityName_eng, weatherOption, dateOption) {
  return `/data1/coverages/${cityName_eng}_estimation/${weatherOption}/${DateUtils.getFormattedDate(
    dateOption,
    '/'
  )}/${weatherOption}.${DateUtils.getFormattedDate(dateOption, '-')}.tiff`;
}

export function getEpinetMapInfo(weatherOption) {
  return `/srv/webapp/resources/mapfiles/${weatherOption}.map`;
}
