export function convertDictIntoCsv(keys, data, delimeter = ',') {
  let csv = '';
  let columns = [];
  keys.map((key) => columns.push(key.displayName));
  csv += columns.join(delimeter);
  csv += '\n';
  data.forEach((item) => {
    let row = [];
    row = keys.map((key) => item[key.name]);
    csv += row.join(delimeter);
    csv += '\n';
  });
  return csv;
}

// 시간단위로 들어오는 양파 모형결과를 일자별 데이터로 가공
//param: 양파모형구동 api 호출로 받아진 데이터
export const getDailyOnionFromHourlyData = (processedData) => {
  const columns = processedData.columns;

  // console.log(rawdata);
  const groupByDay = [];
    let startDate = null;

    let tempArray = [];
  //일자별로 나누기
  for (const data of processedData.rows) {
    if(!startDate) {
      startDate = data.datetime.split(' ')[0];
      tempArray.push(data);
      continue;
    }
    if(startDate === data.datetime.split(' ')[0]) {
      // console.log('같음');
      tempArray.push(data);
      // console.log(tempArray);
    } else {
      // console.log('다름');
      groupByDay.push(tempArray);
      tempArray = [];
      startDate = data.datetime.split(' ')[0];
      tempArray.push(data);
    }
  }

  let rows = [];
  for (const hourly of groupByDay) {
    // console.log(hourly);
  //일자별 평균 구하기
    let dailyData = calculateDailyData(hourly);
    rows.push(dailyData);
  }

  return { columns, rows };
}

// 시간단위로 들어온 데이터의 일평균데이터 반환
// param: 특정 일자에 대한 시간별 데이터
const calculateDailyData = ( rows ) => {
  const tempDatetime = rows[0].datetime.split('/',);
  const datetime = tempDatetime[0] + '-' + tempDatetime[1] + '-' + tempDatetime[2].split(' ')[0];
  let photosynthesis = 0;
  let boltingO = 0;
  let boltingX = 0;
  let totaldvs = 0;
  let leafNumber = 0;
  let leafArea = 0;
  let lai = 0;

  rows.forEach(d => {
    photosynthesis += parseFloat(d.photosynthesis);
    totaldvs += parseFloat(d.totaldvs);
    leafNumber += parseFloat(d.leafNumber);
    leafArea += parseFloat(d.leafArea);
    lai += parseFloat(d.lai);
    if (d.bolting === 'O') {
      boltingO += 1;
    } else if (d.bolting === 'X') {
      boltingX += 1;
    }
  })
  photosynthesis = processOnionData(photosynthesis, rows.length);
  totaldvs = processOnionData(totaldvs, rows.length);
  leafNumber = processOnionData(leafNumber, rows.length);
  leafArea = processOnionData(leafArea, rows.length);
  lai = processOnionData(lai, rows.length);

  const bolting = boltingO >= boltingX ? 'O' : 'X';
  rows = [{ datetime, photosynthesis, bolting, totaldvs, leafNumber, leafArea, lai }];
  return rows[0];
}

export const processOnionData = (data, length) => {
  return Math.round((data /= length) * 100) / 100.0;
}