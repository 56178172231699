import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import SelectEstimation from './SelectEstimation';
import { getPhotosynthesisAPI } from '../../../utils/EpinetAPIUtils.js';

const Div = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    // 줄바꿈 안함
    white-space: nowrap;
    margin: 5px;
`;

const P = styled.p`
    font-weight: bold;
    margin: 1%;
`;

const DatePickerWrapper = styled.div`
    width: 30%;
`;

const SearchBtn = styled.button`
    background-color: #416bdb;
    color: white;
    border-radius: 5px;
    border: 1px solid #416bdb;
    padding: 5px 15px;
    margin-left: 2%;

    &:hover {
        cursor: pointer;
    }
`;

const SelectGraphBar = (props) => {
  const [list, setList] = useState(props.list);

  useEffect(() => {
    setList(props.list);
  }, [props.list]);

  const goSearch = () => {
    if(props.startDate > props.endDate) {
      alert('시작일자와 종료일자를 바르게 지정해주세요');
      return;
    }
    props.searchPhenologyData();
  };

  const checkBeginDate = (e) => {
    if (e > props.endDate) {
      alert('종료일자 이전 날짜만 선택 가능합니다');
      return;
    }
    props.setStartDate(e);
  };

  const checkEndDate = (e) => {
    if (e < props.startDate) {
      alert('시작일자 이후 날짜만 선택 가능합니다');
      return;
    } else if (e < props.beginDate) {
      alert('정식일 이후 날짜만 선택 가능합니다');
      return;
    }
    props.setEndDate(e);
  };

  return (
    <Div>
        <P>
          날짜선택
        </P>
        <DatePickerWrapper>
        <DatePicker
          inputmode='none'
          locale='ko'
          dateFormat='yyyy-MM-dd'
          selected={props.startDate}
          onChange={checkBeginDate}
        />
        </DatePickerWrapper>
        <P>
          ~
        </P>
        <DatePickerWrapper>
        <DatePicker
          inputmode='none'
          locale='ko'
          dateFormat='yyyy-MM-dd'
          selected={props.endDate}
          onChange={checkEndDate}
        />
        </DatePickerWrapper>
        <SearchBtn onClick={goSearch}>검색</SearchBtn>
    </Div>
  );
};

export default SelectGraphBar;