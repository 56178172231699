import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import serviceAreas from '../../data/serviceAreas.json';
import weathers from '../../data/weathers.json';

import TodayPlaceWeatherMolecule from '../UI/TodayPlaceWeatherMolecule';
import MapContainerOrganism from '../UI/MapContainerOrganism';
import DisplayMapMarkerAddress from '../UI/DisplayMapMarkerAddress';
import FarmWeatherForecastContents from '../UI/FarmWeatherForecastContents';
import LookUpFarmWeatherOrganism from '../UI/LookUpFarmWeatherOrganism';
import WeatherColorChartOrganism from '../UI/WeatherColorChartOrganism';
import { getRoundedFloor } from '../../utils/NumberUtil.js';
import { getFarmWeatherForecastAPI } from '../../utils/EpinetAPIUtils.js';
import axios from 'axios';

const Section = styled.section`
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
`;

const Article = styled.article`
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    padding-bottom: 0.5rem;
`;

const FarmArticle = styled.article`
    width: 100%;
    box-sizing: border-box;
    border: 0.5rem solid #d5eebb;
    border-radius: 0.5rem;
`;

const ArticleHeader = styled.header``;

const ArticleSection = styled.section`
    box-sizing: border-box;
    padding: 1rem;
`;

const MapContainerWrapper = styled.div`
    position: relative;
`;

const ColorChartWrapper = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
`;

const MobilePage = (props) => {

  const initialData = {
    selectedDate: new Date(),
    selectedArea: serviceAreas[0],
    selectedMarker: serviceAreas[0].mapInfo.coordinate,
    selectedWeather: weathers[0],
    markerSelectedWeatherValue: null,
    markerAllWeatherValues: null,
    weatherColorChart: null,
    minAndMaxOfWeather: null,
    isInitialLoading: true
  };
  const [selectedDate, setSelectedDate] = useState(initialData.selectedDate);
  const [selectedArea, setSelectedArea] = useState(initialData.selectedArea);
  const [selectedMarker, setSelectedMarker] = useState(
    initialData.selectedMarker
  );
  const [selectedWeather, setSelectedWeather] = useState(
    initialData.selectedWeather
  );
  const [farmWeatherForecastData, setFarmWeatherForecastData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [address, setAddress] = useState('');

  useEffect(() => {
    const getMemberData = async (mobile) => {
      const memberInfo = await fetch(`https://wds.agdcm.kr/regist/owner_view?phone=${mobile}`).then(res => res.json());
      if (memberInfo.status === 'success') {
        let mem = memberInfo.data;
        let lat = mem.lat;
        let lng = mem.lon;

        //로컬스토리지에 멤버정보가 존재한다면 멤버의 농장이 위치한 지역으로 셀렉트
        for (const r of serviceAreas) {
          if(r.areaName_eng === mem.region){
            r.mapInfo.coordinate.lat = lat;
            r.mapInfo.coordinate.lng = lng;
              setSelectedArea(r);
              setSelectedMarker({lat, lng});
          }
        }
      }
    };

    if (localStorage.getItem('user')) {
      getMemberData(localStorage.getItem('user'));
    }
  }, []);

  const { kakao } = window;
  const geocoder = new kakao.maps.services.Geocoder();

  const onChangeDate = (date) => {
    setSelectedDate(date);
  };

  const updateMarker = (newMarker) => {
    setSelectedMarker(newMarker);
  };

  const getValidateData = (data) => {
    if (data) {
      let result = [];
      const weatherItemsCount = data.header[0].length;
      const weatherItems = {
        tmin: 0,
        tmax: 1,
        hm: 2,
        rain: 3,
        ins: 4,
        sunshine: 5,
        wsa: 6,
        wsx: 7
      };

      for (let i = 0; i < weatherItemsCount; i++) {
        let row = [];
        let weatherId = weatherItems[data.header[0][i][1]];
        row.push(
          weathers[weatherId].title_kor +
          '(' +
          weathers[weatherId].measurementUnit +
          ')'
        );
        data.values.map((values) => row.push(getRoundedFloor(values[i][0])));
        result.push(row);
      }
      return result;
    }
  };

  useEffect(() => {
    updateFarmWeatherForecastData(
      selectedMarker.lat,
      selectedMarker.lng,
      selectedArea.location.cityName_eng,
      selectedDate
    );
  }, [selectedDate, selectedMarker, selectedArea, address]);

  const updateFarmWeatherForecastData = async (lat, lng, cityName, date) => {
    const farmWeatherForecastAPI = getFarmWeatherForecastAPI(
      lat,
      lng,
      cityName,
      date
    );

    try {
      setLoading(true);
      setError(null);
      setFarmWeatherForecastData(null);
      // if (lat && lng && cityName && date) {
      await axios.get(farmWeatherForecastAPI).then((response) => {
        const data = response.data;
        // console.log('updateFarmWeatherForecastData', data);
        setFarmWeatherForecastData(getValidateData(data));
      });
      // }
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  //좌표로 주소 얻어오기
  const updateFarmAddress = async (lat, lng) => {
    const coord = new kakao.maps.LatLng(lat, lng);
    const callback = function(result, status) {
      if (status === kakao.maps.services.Status.OK) {
        let resultAddress = result[0].address.address_name;
        setAddress(resultAddress);
        console.log(selectedArea);
      } else if (status === kakao.maps.services.Status.ZERO_RESULT) {
        setAddress('해당 좌표의 주소가 없습니다.');
      }
    };
    try {
      geocoder.coord2Address(coord.getLng(), coord.getLat(), callback); //좌표를 주소로 변환
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    updateFarmAddress(selectedMarker.lat, selectedMarker.lng);
  }, [selectedMarker]);

  //주소로 좌표 얻어오기
  const getLonLatFromVworld = (inputAddress) => {
    if (inputAddress.trim === '') return;
    let callback = function(result, status) {
      if (status === kakao.maps.services.Status.OK) {
        let lng = +result[0].x;
        let lat = +result[0].y;
        let resultAddress = result[0].address.address_name;
        setAddress(resultAddress);
        console.log(result[0]);
        for (const r of serviceAreas) {
          if (result[0].address.region_2depth_name.includes(r.areaName_kor)) {
            console.log(resultAddress);
              // if(resultAddress.includes(r.areaName_kor)){
                r.mapInfo.coordinate.lat = lat;
                r.mapInfo.coordinate.lng = lng;
                setSelectedMarker({lat, lng});
                setSelectedArea(r);
                break;
          }
        }
        updateMarker({ lat, lng });
      } else {
        alert('입력하신 주소 검색에 실패하였습니다');
      }
    };
    geocoder.addressSearch(inputAddress, callback);

    return address;
  };

  return (
    <Section>
      <Article>
        <TodayPlaceWeatherMolecule
          selectedWeather={selectedWeather}
          setSelectedWeather={setSelectedWeather}
          selectedDate={selectedDate}
          selectedArea={selectedArea}
          setSelectedArea={setSelectedArea}
          onChangeDate={onChangeDate}
        />
      </Article>
      {/* <Article>
            <DisplayMarkerWeathers areaWeathers={markerAllWeatherValues} />
          </Article> */}
      <Article>
        <MapContainerWrapper>
          <MapContainerOrganism
            selectedMarker={selectedMarker}
            selectedArea={selectedArea}
            selectedWeather={selectedWeather}
            selectedDate={selectedDate}
            onChangeMarker={updateMarker}
          />
          <ColorChartWrapper>
            <WeatherColorChartOrganism
              selectedArea={selectedArea}
              selectedWeather={selectedWeather}
              selectedDate={selectedDate}
              selectedMarker={selectedMarker}
            />
          </ColorChartWrapper>
        </MapContainerWrapper>
      </Article>
      <FarmArticle>
        <ArticleHeader>
          <DisplayMapMarkerAddress
            address={address}
            getLonLatFromVworld={getLonLatFromVworld}
            isMockTest={false}
          />
        </ArticleHeader>
        <ArticleSection>
          <FarmWeatherForecastContents
            selectedDate={selectedDate}
            farmWeatherForecastData={farmWeatherForecastData}
          />
        </ArticleSection>
        <ArticleSection>
          <LookUpFarmWeatherOrganism
            selectedArea={selectedArea}
            selectedDate={selectedDate}
            selectedMarker={selectedMarker}
          />
        </ArticleSection>
      </FarmArticle>
    </Section>
  );
};

export default MobilePage;
