import React from 'react';
import styled from 'styled-components';

import spinLoading from '../../images/spinLoading.gif';

const Image = styled.img`
  width: 10%;
`;

const LoadingImage = () => {
  return <Image src={spinLoading} alt="로딩 중" />;
};

export default LoadingImage;
