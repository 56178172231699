import React  from 'react';
import styled from 'styled-components';

import SectionTitleComponent from './SectionTitleComponent';
import LoadingImage from './LoadingImage';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TableWrapper = styled.div`
  height: 100%;
  width: 100%;
  /* overflow-y: scroll; */
  @media only screen and (max-width: 1000px) {
    font-size: 8px;
  }
`;

const InfoDiv = styled.div`
  @media only screen and (max-width: 1000px) {
    font-size: 5px;
  }
`;

const Table = styled.table`
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border-collapse: collapse;
  border-spacing: 0;
  /* border: 1px solid #ddd; */
`;

const StickyTh = styled.th`
  height: 100%;
  width: 16%;
  position: sticky;
  top: 0;
  color: white;
  background-color: #7fc8a9;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Tbody = styled.tbody`
  height: 100%;
  width: 100%;
`;

const Td = styled.td`
  width: 16%;
  display: block;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
`;

const HeaderTr = styled.tr`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #7fc8a9;
  box-sizing: border-box;
`;

const Tr = styled.tr`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #d5eebb;
  }
`;

const FarmWeatherForecastContents = (props) => {

  const selectedDate = props.selectedDate;
  const farmWeatherForecastData = props.farmWeatherForecastData;

  return (
    <Wrapper>
      <SectionTitleComponent>기상 예보</SectionTitleComponent>
      {farmWeatherForecastData !== null ? (
        <TableWrapper>
          <Table>
            <thead>
              <HeaderTr>
                <StickyTh>날씨 요소</StickyTh>
                <StickyTh>그저께</StickyTh>
                <StickyTh>어제</StickyTh>
                <StickyTh>
                  오늘
                  <InfoDiv>
                    ({selectedDate.toISOString().split('T')[0]})
                  </InfoDiv>
                </StickyTh>
                <StickyTh>내일</StickyTh>
                <StickyTh>모레</StickyTh>
              </HeaderTr>
            </thead>
            <Tbody>
              {farmWeatherForecastData.map((data, outerIndex) => (
                <Tr key={outerIndex}>
                  {data.map((item, innerIndex) => (
                    <Td key={innerIndex}>
                      {item === null || item === undefined ? '-' : item}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableWrapper>
      ) : (
        <LoadingImage />
      )}
    </Wrapper>
  );
};

export default FarmWeatherForecastContents;
