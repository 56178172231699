import React from 'react';

import './App.css';
import MobilePage from './components_ver02/pages/MobilePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ServiceTitleMolecule from './components_ver02/UI/ServiceTitleMolecule';
import styled from 'styled-components';
import NavBar from './components_ver02/UI/NavBar';
import FarmInfoPage from './components_ver02/pages/FarmInfoPage';

const Header = styled.header`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
`;

const Footer = styled.footer`
    text-align: center;
    box-sizing: border-box;
    padding: 1rem;
    margin-bottom: 2rem;
`;

const LayoutWrapper = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    width: 80%;
    padding-left: 20%;
    box-sizing: border-box;
    font-size: 16px;
    @media only screen and (max-width: 1000px) {
        width: 100%;
        padding: 0;
        font-size: 10px;
    }
`;

const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <LayoutWrapper>
          <PageWrapper>
            <Header>
              <ServiceTitleMolecule />
              <NavBar />
            </Header>
            <Routes>
              <Route path="/" element={<MobilePage />}></Route>
              <Route path="/farmInfo" element={<FarmInfoPage />}></Route>
            </Routes>
            <Footer>ⓒ 국립원예특작과학원 온난화농업대응연구소</Footer>
          </PageWrapper>
        </LayoutWrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
