import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectBar from '../UI/farmInfoPage/SelectBar';
import WeatherList from '../UI/farmInfoPage/WeatherList';
import EstimationGraph from '../UI/farmInfoPage/EstimationGraph';

const Section = styled.section`
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
`;

const ListWrapper = styled.div`
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
`;


function FarmInfoPage(props) {
  const [date, setDate] = useState(new Date());
  // const [latlng, setLatlng] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [user, setUser] = useState(null);
  const [region, setRegion] = useState(null);
  const [plant, setPlant] = useState(null);
  // const [startDate, setStartDate] = useState(new Date());
  const [address, setAddress] = useState(null);
  const [mobile, setMobile] = useState(localStorage.getItem('user'));

  useEffect(() => {
    if(!mobile) return;
    const getMemberData = async () => {
      const memberInfo = await fetch(`https://wds.agdcm.kr/regist/owner_view?phone=${mobile}`).then(res => res.json());
      if(memberInfo.status === 'success'){
        let mem = memberInfo.data;
        setUser(mem);
        // setStartDate(new Date(mem.begindate));
      }
    }
    getMemberData();
  }, [mobile]);

  useEffect(() => {
    if (!user) return;
    setPlant(user.plant);
    setRegion(user.region);
    setLat(user.lat);
    setLng(user.lon);
    setAddress(user.address);
  }, [user]);

  return (
    <Section>
      <SelectBar
        date={date}
        setDate={setDate}
        address={address}
      />
      <ListWrapper>
        <WeatherList
          date={date}
          latlng={{lat, lng}}
          user={user}
          region={region}
        />
      </ListWrapper>
      <EstimationGraph
        lat={lat}
        lng={lng}
        user={user}
        plant={plant}
        region={region}
      />
    </Section>
  );
}

export default FarmInfoPage;
