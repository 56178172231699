import React from 'react';
import styled from 'styled-components';
import weathers from '../../data/weathers.json';

const Select = styled.select`
  color: #555;
  vertical-align: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  border: 0.1rem solid #ccc;
  border-radius: 0.3rem;
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  font-size: 15px;
  &:hover {
    background-color: #90ba27;
    color: white;
    font-weight: bold;
  }
`;

const Option = styled.option`
  vertical-align: center;
  background-color: white;
  text-align: center;
  padding: 0.2rem;
  color: #555;
  &:hover {
    background-color: #90ba27;
    color: white;
    font-weight: bold;
  }
`;

const SelectWeather = (props) => {
  const { selectedWeather, setSelectedWeather } = props;

  const onChange = (e) => {
    const weatherId = e.target.value;
    const targetWeatherItem = weathers.filter(
      (item) => item.id === parseInt(weatherId)
    )[0];
    if (targetWeatherItem) {
      setSelectedWeather(targetWeatherItem);
    }
  };

  return (
    <Select value={selectedWeather.id} onChange={onChange}>
      {weathers.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.title_kor}
        </Option>
      ))}
    </Select>
  );
};

export default SelectWeather;
