import * as epinetAPI from '../data/epinetAPIData.json';
import * as Weather from '../data/weathers.json';
import * as DateUtils from './DateUtils';
import * as NumberUtils from './NumberUtil';

export function getEstimationAPI(
  date,
  cityName,
  lat,
  lng,
  API = epinetAPI.ESTIMATION_API
) {
  return `${API}${DateUtils.getFormattedDate(
    date,
    ''
  )}/${lng},${lat}/json?region=${cityName}`;
}

export function getEstimatedValueFromData(
  data,
  date,
  lat,
  lng,
  weatherOptionTitle
) {
  const idx1 = 'estimation:' + weatherOptionTitle;
  const idx2 = DateUtils.getFormattedDate(date, '');
  const idx3 = lng + ',' + lat;
  return data[idx1][idx2][idx3];
}

export function getFarmWeatherForecastAPI(
  lat,
  lng,
  cityName,
  selectedDate,
  API = epinetAPI.ESTIMATION_API
) {
  let startDate = new Date(selectedDate);
  let endDate = new Date(selectedDate);

  startDate.setDate(startDate.getDate() - 2);
  endDate.setDate(endDate.getDate() + 2);

  return `${API}${DateUtils.getFormattedDate(
    startDate,
    ''
  )}:${DateUtils.getFormattedDate(
    endDate,
    ''
  )}/${lng},${lat}/flatten?region=${cityName}`;
}

export function getDailyAndHourlyFarmWeatherForecastDuringTheGivenPeriod(
  startDate,
  endDate,
  cityName,
  lat,
  lng,
  API = epinetAPI.FarmWeatherForecastAPI
) {
  return `${API}${DateUtils.getFormattedDate(
    startDate,
    ''
  )}:${DateUtils.getFormattedDate(
    endDate,
    ''
  )}/${lng},${lat}/hourly?region=${cityName}`;
}

export function getMinMaxOfWeatherAPI(
  date,
  cityName,
  weatherOptionTitle,
  API = epinetAPI.MinMaxWeather_API
) {
  return `${API}${cityName}/estimation/${weatherOptionTitle}/${DateUtils.getFormattedDate(
    date
  )}/values/json/?region=${cityName}&before_days=0&after_days=0`;
}

export function getColorChartOfWeatherAPI(
  cityName,
  weatherOption,
  API = epinetAPI.WeatherColorChart_API
) {
  return `${API}${weatherOption}/?region=${cityName}`;
}

export function getWeatherValueOfMarkerAPI(
  date,
  cityName,
  lat,
  lng,
  weatherOptionTitle,
  API = epinetAPI.SingleEstimation_API
) {
  return `${API}estimation:${weatherOptionTitle}/${DateUtils.getFormattedDate(
    date,
    ''
  )}/${lng},${lat}/json?region=${cityName}`;
}

export function getWeatherValueFromData(
  data,
  date,
  lat,
  lng,
  weatherOptionTitle
) {
  return data[`estimation:${weatherOptionTitle}`][
    `${DateUtils.getFormattedDate(date, '')}`
  ][`${lng},${lat}`];
}

export function getPhotosynthesisAPI(
  startDate,
  endDate,
  cityName,
  lat,
  lng,
  crop_name
) {
  return `https://wds.agdcm.kr/farm/phenology/${crop_name}/${DateUtils.getFormattedDate(
    startDate,
    ''
  )}:${DateUtils.getFormattedDate(
    endDate,
    ''
  )}/${lng},${lat}/?region=${cityName}`;
}

export function getUserPhotosynthesisAPI(
  startDate,
  endDate,
  cityName,
  lat,
  lng,
  crop_name,
  mobile
) {
  return `https://wds.agdcm.kr/farm/phenologybyuser/${crop_name}/${DateUtils.getFormattedDate(
    startDate,
    ''
  )}:${DateUtils.getFormattedDate(
    endDate,
    ''
  )}/${lng},${lat}/?region=${cityName}&mobile=${mobile}`;
}

export function getHourlyPhotosynthesisFromData(rawData) {
  //"datetime", "광합성량"
  // 광합성량(μmols/m2/s-1)
  // μmols/m<sup>2</sup>/s<sup>-1</sup>
  // const estimationItems = {
  //   datetime: { title: 'datetime', title_kor: '날짜 및 시간' },
  //   photosynthesis: {
  //     title: 'photosynthesis',
  //     title_kor: '광합성량',
  //     measurementUnit:
  //       '{<fragment>μmols/m<sup>2</sup>/s<sup>-1</sup></fragment>}',
  //   },
  //   bolting: { title: 'bolting', title_kor: '추대현상' },
  //   dvs: { title: 'totaldvs', title_kor: '누적 dvs' },
  // };

  let columns = [
    {
      name: 'datetime',
      displayName: '날짜 및 시간',
    },
    {
      name: 'photosynthesis',
      displayName: '광합성량 (μmols/m²/s⁻¹)',
    },
    {
      name: 'bolting',
      displayName: '추대현상',
    },
    {
      name: 'totaldvs',
      displayName: '누적 dvs',
    },
    {
      name: 'leafNumber',
      displayName: '엽수',
    },
    {
      name: 'leafArea',
      displayName: '엽면적',
    },
    {
      name: 'lai',
      displayName: 'LAI',
    },
  ];
  let rows = [];
  const startDate = rawData.header[1];
  let fakeTotalDvs = 0.8999;
  let fakeLeafNumber = 0;
  let fakeLeafArea = 0;
  let fakeLeafLAI = 0;
  let tempDate = new Date(DateUtils.convertInvalidIntoValidDate(startDate));
  tempDate.setHours(0); // 1시부터 시작인지 확인필요
  rawData.values.forEach((value_item, value_idx) => {
    let row = {};
    columns.forEach((col, id) => {
      fakeTotalDvs += 0.0019;
      fakeLeafNumber += (Math.random()/20);
      fakeLeafArea += (Math.random()/10);
      fakeLeafLAI += (Math.random()/10);
      if (col.name === 'datetime') {
        row['datetime'] = `${DateUtils.getFormattedDate(
          tempDate
        )} ${DateUtils.getHour(tempDate)}:00`;
      } else if (col.name === 'photosynthesis') {
        row['photosynthesis'] = value_item[0];
      } else if (col.name === 'bolting') {
        row['bolting'] = fakeTotalDvs >= 1.0 ? 'O' : 'X';
      } else if (col.name === 'totaldvs') {
        row['totaldvs'] = fakeTotalDvs.toFixed(2);
      } else if (col.name === 'leafNumber') {
        row['leafNumber'] = fakeLeafNumber.toFixed(0);
      } else if (col.name === 'leafArea') {
        row['leafArea'] = fakeLeafArea.toFixed(2);
      } else if (col.name === 'lai') {
        row['lai'] = fakeLeafLAI.toFixed(2);
      }
    });
    rows.push(row);
    tempDate.setHours(tempDate.getHours() + 1);
  });
  return { columns: columns, rows: rows };
}

export function getPhenologyEstimationOfNapaCabbageAPI(
  startDate,
  endDate,
  cityName,
  lat,
  lng
) {
  return `https://wds.agdcm.kr/farm/phenology/cabbage/${DateUtils.getFormattedDate(
    startDate,
    ''
  )}:${DateUtils.getFormattedDate(
    endDate,
    ''
  )}/${lng},${lat}/?region=${cityName}`;
}

export function getHourlyPhenologyEstimationOfNapaCabbageFromData(rawData) {
  // console.log('whahahah!!!!!!', rawData);
  //"datetime", "LN", "lai", "dvs", "pltDW", "pltFW", "lossPer", "realFW", "bolting"
  // 엽수, 엽면적지수, 발육단계, 건조증량(g), 잠재 생체중량(포기당:g), 장해피해율(%), 실무게(g), 추대여부
  const estimationItems = {
    datetime: { title: 'datetime', title_kor: '날짜 및 시간' },
    LN: { title: 'LN', title_kor: '엽수' },
    lai: { title: 'lai', title_kor: '엽면적지수' },
    dvs: { title: 'dvs', title_kor: '발육단계' },
    pltDW: { title: 'pltDW', title_kor: '건조증량', measurementUnit: 'g' },
    pltFW: {
      title: 'pltFW',
      title_kor: '잠재 생체중량',
      measurementUnit: 'g/포기당',
    },
    lossPer: {
      title: 'lossPer',
      title_kor: '장해피해율',
      measurementUnit: '%',
    },
    realFW: { title: 'realFW', title_kor: '실무게', measurementUnit: 'g' },
    bolting: { title: 'bolting', title_kor: '추대여부' },
  };

  let columns = [];
  rawData.header[0].forEach((header_item) => {
    columns.push({
      name: header_item,
      displayName: `${estimationItems[header_item].title_kor}${
        estimationItems[header_item].measurementUnit === undefined
          ? ''
          : `(${estimationItems[header_item].measurementUnit})`
      }`,
    });
  });

  let rows = [];
  const startDate = rawData.header[1];
  let tempDate = new Date(DateUtils.convertInvalidIntoValidDate(startDate));
  tempDate.setHours(0);
  rawData.values.forEach((value_item, value_idx) => {
    let row = {};
    // console.log('value item: ', value_item);
    columns.forEach((col, id) => {
      if (col.name === 'datetime') {
        row['datetime'] = `${DateUtils.getFormattedDate(
          tempDate
        )} 🥬${DateUtils.getHour(tempDate)}:00`;
      } else {
        // console.log(`value tiem[${id}]??`, value_item[id]);
        row[col.name] =
          value_item[id] === null || value_item[id] === undefined
            ? '-'
            : NumberUtils.getRoundedFloor(value_item[id]);
      }
    });
    rows.push(row);
    tempDate.setHours(tempDate.getHours() + 1);
  });
  return { columns: columns, rows: rows };
}
//무 생육모형 추가-START
export function getPhenologyRadishFromData(rawData) {
// ['datetime', 'LN', 'lai', 'netTmm', 'pltleafDW','pltrootDW','pltleafFW','pltrootFW', 'lossPer', 'bolting']

  let columns = [
    {
      name: 'datetime',
      displayName: '날짜 및 시간',
    },
    {
      name: 'NL',
      displayName: '엽수',
    },
    // {
    //   name: 'lai',
    //   displayName: '엽면적지수',
    // },
    // {
    //   name: 'netTmm',
    //   displayName: '광합성량',
    // },
    {
      name: 'pltleafDW',
      displayName: '지상부 건물중량(g)',
    },
    {
      name: 'pltleafFW',
      displayName: '지상부 생체중량(g)',
    },
   {
      name: 'pltrootDW',
      displayName: '지하부 건물중량(g)',
    },
     {
      name: 'pltrootFW',
      displayName: '지하부 생체중량(g)',
    },
   {
      name: 'lossPer',
      displayName: '장해피해율',
    },
   {
      name: 'bolting',
      displayName: '추대여부',
    }
  ];
  let rows = [];
  const startDate = rawData.header[1];
  let tempDate = new Date(DateUtils.convertInvalidIntoValidDate(startDate));
  tempDate.setHours(0); // 1시부터 시작인지 확인필요
//  console.log('whahahah!!!!!!', rawData);
  rawData.values.forEach((value_item, value_idx) => {
    let row = {};
    columns.forEach((col, id) => {

// ['datetime', 'LN', 'lai', 'netTmm', 'pltleafDW','pltrootDW','pltleafFW','pltrootFW', 'lossPer', 'bolting']
       if (col.name === 'datetime') {
             row['datetime'] = value_item[0];
        } else if (col.name === 'NL') {                             // 엽수
             row['NL'] =  value_item[1].toFixed(1);
        } else if (col.name === 'lai') {                            //엽면적지수
             row['lai'] =  value_item[2].toFixed(1);
        } else if (col.name === 'netTmm') {                         //광합성량
             row['netTmm'] =  value_item[3].toFixed(1);
        } else if (col.name === 'pltleafDW') {                     //지상부 건물증량
             row['pltleafDW'] = parseFloat(value_item[4].toFixed(1)) >= 0 ?
               parseFloat(value_item[4].toFixed(1)) : 0;
        } else if (col.name === 'pltleafFW') {                     //지상부 생체중량
             row['pltleafFW'] =  parseFloat(value_item[6].toFixed(1)) >= 0 ?
               parseFloat(value_item[6].toFixed(1)) : 0;
        } else if (col.name === 'pltrootDW') {                     //지하부 건물중량
             row['pltrootDW'] =  parseFloat(value_item[5].toFixed(1)) >= 0 ?
               parseFloat(value_item[5].toFixed(1)) : 0;
        } else if (col.name === 'pltrootFW') {                     //지하부 생체중량
             row['pltrootFW'] =  parseFloat(value_item[7].toFixed(1)) >= 0 ?
               parseFloat(value_item[7].toFixed(1)) : 0;
        } else if (col.name === 'lossPer') {                       //장해피해율
             row['lossPer'] = value_item[8].toFixed(1);
        } else if (col.name === 'bolting') {                       // 추대여부
             row['bolting'] =  value_item[9] >= 1.0 ? 'O' : 'X';
        }
    });
    rows.push(row);
    tempDate.setHours(tempDate.getHours() + 1);
  });
  return { columns: columns, rows: rows };
}
// 무 생육모형 수정 -END-
export function getDailyFarmWeatherEstimationFromData(rawData) {
  const weatherInfos = {
    tmin: { ...Weather[0] },
    tmax: { ...Weather[1] },
    hm: { ...Weather[2] },
    rain: { ...Weather[3] },
    ins: { ...Weather[4] },
    sunshine: { ...Weather[5] },
    wsa: { ...Weather[6] },
    wsx: { ...Weather[7] },
  };
  const startDate = new Date(
    DateUtils.convertInvalidIntoValidDate(rawData.header[1])
  );
  let columns = [{ name: 'date', displayName: '날짜' }];
  let rows = [];
  rawData.header[0].forEach((header_item) =>
    columns.push({
      name: header_item[1],
      displayName: `${weatherInfos[header_item[1]].title_kor}${
        weatherInfos[header_item[1]].measurementUnit === undefined
          ? ''
          : `(${weatherInfos[header_item[1]].measurementUnit})`
      }`,
    })
  );

  rawData.values.forEach((value_item, value_idx) => {
    let row = {};
    let temp_date = new Date(startDate);
    temp_date.setDate(temp_date.getDate() + value_idx);
    row['date'] = DateUtils.getFormattedDate(temp_date, '-');
    columns.forEach((col, id) => {
      if (col.name !== 'date') {
        row[col.name] =
          value_item[id - 1][0] === null || value_item[id - 1][0] === undefined
            ? '-'
            : NumberUtils.getRoundedFloor(value_item[id - 1][0]);
      }
    });
    rows.push(row);
  });
  return { columns: columns, rows: rows };
}
