import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import 'react-datepicker/dist/react-datepicker.css';
import './SelectDate.css';

registerLocale('ko', ko);

function SelectDate(props) {
  const { onChangeDate } = props;
  const [date, setDate] = useState(new Date());

  return (
    <DatePicker
      inputmode="none"
      locale="ko"
      dateFormat="yyyy-MM-dd"
      selected={date}
      onChange={(e) => {
        onChangeDate(e);
        setDate(e);
      }}
    />
  );
}

export default SelectDate;
