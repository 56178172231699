import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const PhenologyFactorSelect = styled.select`
    font-size: 15px;
    border-radius: 5px;
    border-color: #cccccc;
    margin-right: 10px;
    padding: 0.4rem;
    &:hover {
        cursor: pointer;
    }
`;

const SelectEstimation = (props) => {
  const [list, setList] = useState(null);
  const [factor, setFactor] = useState(props.factor);

  useEffect(() => {
    props.setFactor(factor);
  }, [factor]);

  useEffect(() => {
    setList(props.list);
  }, [props.list]);

  useEffect(() => {
    renderSelectEstimation(list);
  }, [list]);

  const renderSelectEstimation = (list) => {
    if(!list) return;
    let listWithoutDate = list.filter(item => item.name !== 'datetime' && item.name !== 'bolting');
    return listWithoutDate.map(m => (
      <option key={m.name} value={m.name}>{m.displayName}</option>
    ));
  }

  const changeFactor = e => {
    setFactor(e.target.value);
  }

  return (
    <PhenologyFactorSelect
      defaultValue={factor}
      onChange={changeFactor}
    >
      {renderSelectEstimation(list)}
    </PhenologyFactorSelect>
  );
};

export default SelectEstimation;