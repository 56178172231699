import React, { useEffect, useState } from 'react';
import {
  Map,
  TileLayer,
  WMSTileLayer,
  Marker,
  ScaleControl,
} from 'react-leaflet';

import './MapContainerOrganism.css';
import * as EpinetMapUtils from '../../utils/EpinetMapUtils';

const MapContainerOrganism = (props) => {
  const { selectedMarker, selectedArea, selectedWeather, selectedDate, onChangeMarker } = props;
  const [marker, setMarker] = useState(selectedArea.mapInfo.coordinate);


  const mapUrls = {
    base: 'https://xdworld.vworld.kr/2d/Base/service/{z}/{x}/{y}.png',
    hybrid: 'https://xdworld.vworld.kr/2d/Hybrid/service/{z}/{x}/{y}.png',
    satellite: 'https://xdworld.vworld.kr/2d/Satellite/service/{z}/{x}/{y}.jpeg',
  };

  const onClickMap = (e) => {
    setMarker(e.latlng); //얘가 마커를 찍는 애
    onChangeMarker(e.latlng);
  };

  useEffect(() => {
    setMarker(selectedMarker);
    onChangeMarker(selectedMarker);
  }, [selectedMarker]);

  useEffect(() => {
    setMarker(selectedArea.mapInfo.coordinate);
    onChangeMarker(selectedArea.mapInfo.coordinate);
  }, [selectedArea]);

  return (
    <Map
      center={[
        selectedArea.mapInfo.coordinate.lat,
        selectedArea.mapInfo.coordinate.lng,
      ]}
      zoom={selectedArea.mapInfo.zoom}
      minZoom={6.5}
      // style={{ height: '100%' }}
      onClick={onClickMap}
    >
      <ScaleControl
        metric={true}
        imperial={false}
        ScaleControl={true}
      ></ScaleControl>
      <TileLayer url={mapUrls.satellite} attribution="VWORLD" />
      <WMSTileLayer url={mapUrls.hybrid} />
      <WMSTileLayer
        url="https://wds.agdcm.kr/mapserver/"
        format="image/png"
        transparent="true"
        opacity="0.5"
        layers="default,SGG_OUTLINE"
        version="1.3.0"
        data={EpinetMapUtils.getEpinetMapData(
          selectedArea.location.cityName_eng,
          selectedWeather.title,
          selectedDate
        )}
        map={EpinetMapUtils.getEpinetMapInfo(selectedWeather.title)}
        sig_cd={selectedArea.municipalLevelDivisionCode}
      />
      <Marker position={[marker.lat, marker.lng]}></Marker>
    </Map>
  );
};

export default MapContainerOrganism;
