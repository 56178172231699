export function getRoundOffFloor(number, offDigit = 1) {
  // 소수점 digit자리 초과 버림
  // 예: getRoundOffFloor(12.34567, 2)의 결과는 '1.34'
  const offDigitInDecimal = Math.pow(10, offDigit);
  return String(Math.floor(number * offDigitInDecimal) / offDigitInDecimal);
}

export function getRoundedFloor(number, roundDigit = 1) {
  // 예: getRoundedFloor(12.34567, 2)의 결과는 '1.35'
  if (number !== null) {
    parseFloat(number);
    return String(number.toFixed(roundDigit));
  } else {
    return null;
  }
}
