import React from 'react';
import styled from 'styled-components';
import brandSymbol from '../../images/brand-symbol.png';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
    padding: 1.5rem;
  flex-wrap: wrap;
`;

const Img = styled.img`
  height: 2rem;
  box-sizing: border-box;
`;

const MainTitle = styled.div`
  color: #333333;
  font-size: 1.5rem;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 700;
`;

const SubTitle = styled.div`
  color: #666666;
  font-size: 1.5rem;
  box-sizing: border-box;
  font-weight: 550;
`;

const ServiceTitleMolecule = (props) => {
  return (
    <Wrapper>
      <Img src={brandSymbol} alt="채소 주산지 생육정보 시스템" />
      <MainTitle>채소 주산지</MainTitle>
      <SubTitle>생육정보 시스템</SubTitle>
    </Wrapper>
  );
};

export default ServiceTitleMolecule;
