import React from 'react';
import styled from 'styled-components';
import PhenologyChart from './PhenologyChart.js';
import SelectEstimation from './SelectEstimation.js';
import LoadingImage from '../LoadingImage.js';


const DownloadBtn = styled.a`
    box-sizing: border-box;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    margin: 0.5rem;
    background-color: #426cdd;
    cursor: pointer;
    font-size: 1rem;
    border-style: none;
    border-radius: 0.5rem;
    transition: all 100ms ease-in 0.05s;
    text-decoration: none;
    color: white;
    line-height: 1.5rem;
    text-align: center;

    &:hover {
        background-color: #90ba27;
        color: white;
        font-weight: bold;
    }
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    // 줄바꿈 안함
    white-space: nowrap;
    margin: 5px;
`;

const P = styled.p`
    font-weight: bold;
    margin: 0 10px 0 0;
`;

const RenderGraph = ({ startDate, endDate, user, isSearch, factor, phenologyData, rows, downloadUrl, list, setFactor, loading }) => {

  if(loading) {
    return <LoadingImage />
  }

  return (
    <>
      <DownloadBtn
        style={{ display: (phenologyData && !loading) ? 'block' : 'none' }}
        href={downloadUrl}
        download={`생육모형구동결과.csv`}
      >
        생육모형 구동결과 다운로드
      </DownloadBtn>
      <Label>
        <P>
          요소선택
        </P>
        <SelectEstimation
          list={list}
          factor={factor}
          setFactor={setFactor}
        />
      </Label>
      <PhenologyChart
        user={user}
        factor={factor}
        phenologyData={phenologyData}
        rows={rows}
        isSearch={isSearch}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );


};
export default RenderGraph;