import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import closeImage from '../../images/closeBtn.png';
import rightArrow from '../../images/rightArrow.png';
import memberList from '../../data/memberInfo.json';

const ModelCloseBtn = styled.button`
    padding: 0;
    border: none;
    background-color: white;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
        cursor: pointer;
    }
`;

const CloseBtn = styled.img`
    width: 100%;
    height: 100%;
`;

const ModalTitle = styled.div`
    font-weight: 700;
    font-size: large;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
`;
const InpupWrapper = styled.div`
    border: 1px solid rgba(183, 183, 183, 0.72);
    border-radius: 2px;
    padding: 5px;
    margin-bottom: 10px;
`;
const WriteMobileNum = styled.input`
    width: 100%;
    outline: none;
    border: none;
    font-size: 14px;
`;
const LoginBtn = styled.div`
    background-color: #00733f;
    border: 1px solid #00733f;
    border-radius: 2px;
    font-size: 14px;
    color: white;
    padding: 5px;
    text-align: center;
    font-weight: 700;

    &:hover {
        cursor: pointer;
    }
`;

const GeneralNotice = styled.div`
    font-size: 14px;
    text-align: center;
    margin: 25px 10px;
`;

const RegisterBtn = styled.div`
    text-align: center;
    font-size: 14px;
    color: #0b60d2;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`;

const MoveToRegister = styled.img`
    width: 15px;
    height: 15px;
    margin-top: 2px;
`;

const LoginByMobile = props => {
  const [mobileNum, setMobileNum] = useState(''); //회원디비와 비교할 전화번호
  const [isMember, setIsMember] = useState(false); //회원정보 일치여부
  const [memberName, setMemberName] = useState(''); //회원정보가 확인된 경우 회원이름
  const [user, setUser] = useState(null);
  const [inputValue, setInputValue] = useState(''); //휴대전화번호 입력값 input 세팅

  // input에 입력된 값을 mobileNum에 저장
  const inputMobileNum = e => {
    setMobileNum(e.target.value);
  };

  // 멤버인지 확인
  const checkIsMember = async () => {
    if (!mobileNum.trim()) {
      alert('휴대전화번호를 입력 후 로그인해주세요');
      return;
    }
    if (mobileNum.length > 11) {
      alert('11자리의 휴대전화번호를 바르게 입력해주세요');
      setMobileNum('');
      return;
    }

    let memberInfo = await fetch(`https://wds.agdcm.kr/regist/owner_view?phone=${mobileNum}`)
      .then(res => res.json());

    if (memberInfo.status === 'success') {
      setIsMember(true);

      if (localStorage.getItem('user')) {
        localStorage.removeItem('user');
      }
      localStorage.setItem('user', mobileNum);
    } else {
      alert('회원 정보가 존재하지 않습니다');
    }
  };

  useEffect(() => {
    setInputValue(mobileNum);
  }, [mobileNum]);

  useEffect(() => {
    if (isMember === true) {
      // alert(user.name + '님 환영합니다');
      window.location.href = '/farmInfo';
    }
  }, [isMember]);

  const goToRegisterFarm = () => {
    props.closeLoginModal();
    props.openRegisterModal();
  };

  return (
    <>
      <ModelCloseBtn onClick={props.closeLoginModal}><CloseBtn src={closeImage} alt={'close'} /></ModelCloseBtn>
      <ModalTitle>농장 등록시 사용한 전화번호로 로그인하세요</ModalTitle>
      <InpupWrapper><WriteMobileNum type='text' value={inputValue} onChange={inputMobileNum}
                                    placeholder='휴대전화번호'></WriteMobileNum></InpupWrapper>
      <LoginBtn onClick={checkIsMember}>로그인</LoginBtn>
      <GeneralNotice>접속이 안되시나요? <br />내 농장 정보보기는  <br /> 농장 등록후 이용이 가능합니다</GeneralNotice>
      <RegisterBtn onClick={goToRegisterFarm}>내 농장 등록하러 가기 <MoveToRegister src={rightArrow} alt='등록하기' /></RegisterBtn>
    </>
  );
};

export default LoginByMobile;