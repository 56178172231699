import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ListCard from './ListCard';
import { logDOM } from '@testing-library/react';
import LoadingImage from '../LoadingImage.js';

const EstimationList = (props) => {
  const [list, setList] = useState(null);
  const [phenologyData, setPhenologyData] = useState(props.phenologyData);


  useEffect(() => {
    if(props.phenologyData) {
    setPhenologyData(props.phenologyData.rows);
    setList(props.phenologyData.columns);
    } else {
      setPhenologyData(null);
      setList(null);
    }
  }, [props.phenologyData]);


  const RenderPhenologyData = ({data, list}) => {
    if(!data || !list) {
      return (
        <div></div>
      )
    }
    if(props.loading) {
      console.log(props.loading);
      return (
        <LoadingImage />
      );
    }
    let dataForRender = data[data.length - 1];
    let listWithoutDate = list.filter(item => item.name !== 'datetime');

    const renderedPhenologyListCard = listWithoutDate.map((item) => (
      <ListCard key={item.name} name={item.displayName} value={dataForRender[item.name]} />
    ));
    return (
         <>
           {renderedPhenologyListCard}
         </>
      )
  }

  return (
    <>
      <RenderPhenologyData
        data={phenologyData} list={list}></RenderPhenologyData>
    </>
  );
};

export default EstimationList;