import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import LoadingImage from './LoadingImage';
import WeatherColorChartWrapper from './WeatherColorChartWrapper';

import * as EpinetApiUtils from '../../utils/EpinetAPIUtils';

const ColorLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 7rem;
  padding: 0;
  margin-right: 1rem;
  margin-bottom: 2rem;
  opacity: 0.95;
  line-height: 1rem;
  font-size: 1rem;
  background-color: #f2f2f2;
  border-radius: 0.3rem;
  @media only screen and (max-width: 1000px) {
    width: 4rem;
    font-size: 10px;
  }
`;

function WeatherColorChartOrganism(props) {
  const { selectedDate, selectedArea, selectedWeather, selectedMarker } = props;
  const [weatherColorChart, setWeatherColorChart] = useState(null);
  const [minMaxValue, setMinMaxValue] = useState(null);

  const updateWeatherColorChart = async () => {
    const cityName = selectedArea.areaName_eng;
    const weatherOptionTitle = selectedWeather.title;
    const weatherColorChartAPI = EpinetApiUtils.getColorChartOfWeatherAPI(
      cityName,
      weatherOptionTitle
    );
    console.log('updateWeatherColorChart', weatherColorChartAPI);
    try {
      setWeatherColorChart(null);
      await axios.get(weatherColorChartAPI).then((response) => {
        const data = response.data;
        const newData = data.map((item, idx) => {
          const newId = idx + 1;
          return {
            id: newId,
            value: item[1],
            nextValue: data[idx + 1] !== undefined ? data[idx + 1][1] : null,
            color: item[2],
          };
        });
        console.log('colorchart', newData);
        setWeatherColorChart(newData);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const updateMinMaxValue = async () => {
    const date = selectedDate;
    const cityName = selectedArea.areaName_eng;
    const weatherOptionTitle = selectedWeather.title;
    const minMaxWeatherAPI = EpinetApiUtils.getMinMaxOfWeatherAPI(
      date,
      cityName,
      weatherOptionTitle
    );
    console.log('minMaxWeatherAPI', minMaxWeatherAPI);
    try {
      setMinMaxValue(null);
      await axios.get(minMaxWeatherAPI).then((response) => {
        const data = response.data;
        setMinMaxValue(data);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    updateWeatherColorChart();
    updateMinMaxValue();
  }, [selectedDate, selectedArea, selectedWeather, selectedMarker]);

  return (
    <>
      {weatherColorChart !== null && minMaxValue !== null ? (
        <WeatherColorChartWrapper
          weatherColorChart={weatherColorChart}
          minMaxValue={minMaxValue}
          selectedDate={selectedDate}
          selectedArea={selectedArea}
          selectedMarker={selectedMarker}
          selectedWeather={selectedWeather}
        />
      ) : (
        <ColorLegendWrapper>
          <LoadingImage />
        </ColorLegendWrapper>
      )}
    </>
  );
}

export default WeatherColorChartOrganism;
