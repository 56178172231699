import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FarmWeatherForecastDuringTheGivenPeriodContents from './FarmWeatherForecastDuringTheGivenPeriodContents';
import PhenologyEstimationContents from './PhenologyEstimationContents';
import DateAndLabel from '../../components/UI/molecules/DateAndLabel';
import {
  getPhotosynthesisAPI,
  getHourlyPhotosynthesisFromData,
  getPhenologyRadishFromData,
} from '../../utils/EpinetAPIUtils';

import onion from '../../images/onion.png';
import daicon from '../../images/daicon.png';
import SectionTitleComponent from './SectionTitleComponent';

const Wrapper = styled.section`
  width: 100%;
  font-size: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DateRangeWrapper = styled.nav`
  width: 100%;
  display: flex;
`;

const Navigation = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavButton = styled.button`
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  @media only screen and (min-width: 1000px) {
    width: 100%;
    margin: 0.5rem;
  }
  cursor: pointer;
  font-size: 1rem;
  color: gray;
  box-sizing: border-box;
  border: none;
  background: none;
  text-align: left;
  text-align: center;
  &:hover {
    background-color: #90ba27;
    border-radius: 0.5rem;
    color: white;
  }
`;

const SearchButton = styled.button`
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
  @media only screen and (min-width: 100px) {
    width: 150px;
    height:20px
    margin: 0.1rem;
  }
  cursor: pointer;
  font-size: 1rem;
  color: black;
  box-sizing: border-box;
  border: none;
  background: none;
  text-align: left;
  text-align: center;
  &:hover {
    background-color: #90ba27;
    border-radius: 0.5rem;
    color: white;
  }
`;



const SelctedNavButton = styled.button`
  box-sizing: border-box;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  @media only screen and (min-width: 1000px) {
    width: 100%;
    margin: 0.5rem;
  }
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  border: none;
  background: none;
  /* border-radius: 0.5rem;
  background-color: #90ba27; */
  /* color: white; */
  /* color: #90ba27; */
  /* border-bottom: solid #90ba27; */
  border-bottom: solid #444941;
  color: #444941;
  /* border-width: 2px; */
  &:hover {
    background-color: #90ba27;
    border-radius: 0.5rem;
    color: white;
  }
`;

const Article = styled.article`
  width: 100%;
  height: 50vh;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  box-sizing: border-box;
`;

const LookUpFarmWeatherOrganism = (props) => {
  const { selectedArea, selectedMarker } = props;
  const PREDICTION_DAYS = 6;

  const today = new Date();
//  let tempEndDate = new Date();
  let tempStartDate  = new Date();
  tempStartDate.setDate(today.getDate() - PREDICTION_DAYS);

  const [refd, setRefd] = useState(false);
  const [startDate, setStartDate] = useState(new Date(tempStartDate));
  const [endDate, setEndDate] = useState(today);
  // const [warning, setWarning] = useState(false);
  const [selectedButton, setSelectedButton] = useState('1');

  const [open, setOpen] = React.useState(false);
  const [doSearch, setDoSearch] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onClickNavButton = (e) => {
    setDoSearch(true);
    setSelectedButton(e.target.id);
  };

  const onChangeStartDate = (e) => {
    let minStartDate = new Date(endDate);
    minStartDate.setDate(minStartDate.getDate() - 365);
    if (e > endDate) {
      handleClickOpen();
      return false;
    }
    if (e < minStartDate) {
      handleClickOpen();
      return false;
    }
    setStartDate(e);
    return true;
  };

  const onChangeEndDate = (e) => {
    let maxEndDate = new Date(startDate);
    maxEndDate.setDate(maxEndDate.getDate() + 365);
    if (e < startDate) {
      handleClickOpen();
      return false;
    }
    if (e > maxEndDate) {
      handleClickOpen();
      return false;
    }
    setEndDate(e);
    return true;
  };
  useEffect(() => {
    console.log('LookUpFarmWeatherOrganism useEffect')
  },[]);

  return (
    <Wrapper>
      <SectionTitleComponent>기간별 예보/생육모형 구동</SectionTitleComponent>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'🙊 시작일과 종료일을 올바르게 선택하여주세요.'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            시작일은 종료일보다 클 수 없으며, 기간은 366일을 초과할 수 없습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>

      <DateRangeWrapper>
        <DateAndLabel
          selectedDate={startDate}
          onChangeDate={onChangeStartDate}
          label="시작일"
        />
        <DateAndLabel
          selectedDate={endDate}
          onChangeDate={onChangeEndDate}
          label="종료일"
        />
    <SearchButton onClick={() => {
        setRefd(!refd);
        setDoSearch(true);
        }}
    size="small"> 조회
    </SearchButton>

      </DateRangeWrapper>
      <Navigation>
        {selectedButton === '1' ? (
          <SelctedNavButton id={1} onClick={onClickNavButton}>
            기상
          </SelctedNavButton>
        ) : (
          <NavButton id={1} onClick={onClickNavButton}>
            기상
          </NavButton>
        )}
        {selectedButton === '2' ? (
          <SelctedNavButton id={2} onClick={onClickNavButton}>
            무 생육모형
          </SelctedNavButton>
        ) : (
          <NavButton id={2} onClick={onClickNavButton}>
            무 생육모형
          </NavButton>
        )}
        {selectedButton === '3' ? (
          <SelctedNavButton id={3} onClick={onClickNavButton}>
            양파 생육모형
          </SelctedNavButton>
        ) : (
          <NavButton id={3} onClick={onClickNavButton}>
            양파 생육모형
          </NavButton>
        )}
        {/* <NavButton id={2} onClick={onClickNavButton}>
          무 생육모형
        </NavButton>
        <NavButton id={3} onClick={onClickNavButton}>
          양파 생육모형
        </NavButton> */}
      </Navigation>
      <Article>
        {selectedButton === '1' ? (
          <FarmWeatherForecastDuringTheGivenPeriodContents
            selectedArea={selectedArea}
            startDate={startDate}
            endDate={endDate}
            selectedMarker={selectedMarker}
            refd={refd}
          />
        ) : null}
        {selectedButton === '2' ? (
          <PhenologyEstimationContents
            cropType={{
              title_eng: 'radish',
              title_ko: '무',
              icon: '🌱',
              iconImage: daicon,
            }}
            selectedArea={selectedArea}
            startDate={startDate}
            endDate={endDate}
            selectedMarker={selectedMarker}
            getPhenologyEstimaionAPI={getPhotosynthesisAPI}
            getRefinedPheonologyEstimationData={getPhenologyRadishFromData}
            refd={refd}
            doSearch={doSearch}
            setDoSearch={setDoSearch}
          />
        ) : null}
        {selectedButton === '3' ? (
          <PhenologyEstimationContents
            cropType={{
              title_eng: 'onion',
              title_ko: '양파',
              icon: '🧅',
              iconImage: onion,
            }}
            selectedArea={selectedArea}
            startDate={startDate}
            endDate={endDate}
            selectedMarker={selectedMarker}
            getPhenologyEstimaionAPI={getPhotosynthesisAPI}
            getRefinedPheonologyEstimationData={getHourlyPhotosynthesisFromData}
            refd={refd}
            doSearch={doSearch}
            setDoSearch={setDoSearch}
          />
        ) : null}
      </Article>
    </Wrapper>
  );
};

export default LookUpFarmWeatherOrganism;
