import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import LoginByMobile from './LoginByMobile.js';
import RegisterFarm from './RegisterFarm.js';

const NavigationWrapper = styled.nav`

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
    }

    li {
        margin-right: 10px;
        text-decoration: none;
        padding: 1%;
        background-color: #00ADE5;
        color: #ffffff;
        border-radius: 20px;
        position: absolute;
        right: 0;
        bottom: 0;
        
        &:hover{
            cursor: pointer;
        }
    }
    
    a {
        text-decoration: none;
        color: white;
    }
`;

const ModalWrapper = styled.div`
    width: 40%;
    max-width: 250px;
    min-width: 240px;
    padding: 30px;
    position: absolute;
    left: 50%;
    bottom: -40vh;
    transform: translate(-50%, 50%);
    background: white;
    border-radius: 5px;
    z-index: 2;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
`;

const RegisterFarmWrapper = styled.div`
    width: 50%;
    max-width: 400px;
    min-width: 270px;
    position: absolute;
    left: 50%;
    bottom: -40vh;
    transform: translate(-50%, 50%);
    background: white;
    border-radius: 5px;
    z-index: 2;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
`;

function NavBar(props) {
  const location = useLocation();
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [isOpenRegister, setIsOpenRegister] = useState(false);

  const showLoginModal = () => {
    if(isOpenRegister) return;
    setIsOpenLogin(true);
  };
  const closeLoginModal = () => {
    setIsOpenLogin(false);
  }

  const openRegisterModal = () => {
    setIsOpenRegister(true);
  }

  const closeRegisterModal = () => {
    setIsOpenRegister(false);
  }

  const goToPreviousModal = () => {
    setIsOpenRegister(false);
    setIsOpenLogin(true);
  }

  return (
    <>
      <NavigationWrapper>
        <ul>
          {location.pathname !== '/' && <li><Link to={'/'}>지도정보 보기</Link></li>}
          {location.pathname !== '/farmInfo' &&
            <li onClick={showLoginModal}>내 농장정보 보기</li>}
        </ul>
      </NavigationWrapper>
      <ModalWrapper style={{ display: isOpenLogin ? 'block' : 'none' }}>
        <LoginByMobile
          closeLoginModal={closeLoginModal}
          openRegisterModal={openRegisterModal}
        >
        </LoginByMobile>
      </ModalWrapper>
      <RegisterFarmWrapper
        style={{ display: isOpenRegister ? 'block' : 'none' }}
      >
        <RegisterFarm
          closeRegisterModal={closeRegisterModal}
          goToPreviousModal={goToPreviousModal}
        >
        </RegisterFarm>
      </RegisterFarmWrapper>
    </>
  );
}

export default NavBar;